import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'

export function Seo({ route, site, ...rest }) {
	const router = useRouter()
	const alt = route.openGraph?.image?.alt
	const openGraphImage = route?.openGraph?.image || site?.openGraph?.image
	const images = openGraphImage
		? [
				{
					// Facebook
					url: urlFor(openGraphImage).width(1200).height(630).url(),
					width: 1200,
					height: 630,
					alt
				}
		  ]
		: []

	const title = route.openGraph.title || site.openGraph.title
	const formattedTitle = title ? `Stuf Storage | ${title}` : 'Stuf Storage'
	const description = route.openGraph.description || site.openGraph.description

	const url = `${process.env.NEXT_PUBLIC_SITE_URL}${router.asPath}`

	return (
		<NextSeo
			title={formattedTitle}
			// TODO: fix defaultTitle not working when title is missing
			// titleTemplate='Stuf Storage | %s'
			// defaultTitle='Stuf Storage'
			description={description}
			// TODO: Fix me
			// canonical=""
			openGraph={{
				url,
				title,
				description,
				images,
				site_name: 'Stuf Storage',
				type: 'website'
			}}
			twitter={{
				handle: site.twitter,
				cardType: 'summary_large_image'
			}}
			{...rest}
		/>
	)
}
