import Button from '@mui/material/Button'
import Image from 'next/image'
import Link from 'next/link'
import { useDispatch } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { setActiveMetro, setCityOrAddress } from '../../slices/searchSlice'
import { TRANSLATIONS } from '../../util/constants'
import stufkey from '../../public/stuf-digital-key.png'

const NotYourAverageStorage = ({ t }) => {
	const dispatch = useDispatch()

	const handleClick = () => {
		dispatch(setCityOrAddress(''))
		dispatch(setActiveMetro(null))
	}

	return (
		<div className='px-6 py-11 lg:px-20 flex flex-col lg:flex-row items-center'>
			<div className='lg:w-1/2 lg:mr-20'>
				<h2 className='text-center lg:text-left capitalize text-brand-dark-blue text-3xl lg:text-4xl font-bold'>
					{t('not-your-average-storage', { ns: 'index' })}
				</h2>
				<hr className='h-[3px] w-[50px] mt-6 mb-10 lg:mb-[72px] mx-auto lg:ml-0 bg-brand-orange border-0' />
				<h3 className='text-2xl font-serif text-brand-dark-blue font-semibold'>
					{t('storage-in-your-neighborhood', { ns: 'index' })}
				</h3>
				<p className='font-serif lg:text-xl mb-6'>
					{t('we-transform-underutilized-spaces-in-nearby-buildings', {
						ns: 'index'
					})}
				</p>
				<h3 className='text-2xl font-serif text-brand-dark-blue font-semibold'>
					{t('secure-inviting-spaces', { ns: 'index' })}
				</h3>
				<p className='font-serif lg:text-xl mb-6'>
					{t('our-clean-bright-and-welcoming-spaces', { ns: 'index' })}
				</p>
				<h3 className='text-2xl font-serif text-brand-dark-blue font-semibold'>
					{t('digital-key-entry', { ns: 'index' })}
				</h3>
				<p className='font-serif lg:text-xl mb-6'>
					{t('safe-simple-and-to-your-fingertips', { ns: 'index' })}
				</p>
				<h3 className='text-2xl font-serif text-brand-dark-blue font-semibold'>
					{t('personalized-service', { ns: 'index' })}
				</h3>
				<p className='font-serif lg:text-xl mb-10 lg:mb-16'>
					{t('personalized-service-subtitle', { ns: 'index' })}
				</p>

				<Link legacyBehavior href={'/search-available-units'}>
					<Button
						variant='outlined'
						sx={{
							borderRadius: '99px',
							fontSize: '18px',
							textTransform: 'capitalize'
						}}
						fullWidth
						onClick={handleClick}
					>
						{t('explore-our-storage-facilities', { ns: 'index' })}
					</Button>
				</Link>
			</div>
			<div className='lg:w-1/2 mt-10 lg:mt-0'>
				<Image src={stufkey} alt='Digital key' />
			</div>
		</div>
	)
}

export default withTranslation(TRANSLATIONS)(NotYourAverageStorage)
