import Image from 'next/image'
import Link from 'next/link'
import { Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { TRANSLATIONS } from '../../util/constants'
import { CaretLeft, CaretRight } from '@phosphor-icons/react'
import { withTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
// import required modules
import { Navigation } from 'swiper/modules'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import { setActiveMetro, setCityOrAddress } from '../../slices/searchSlice'

const DiscoverNewLocations = ({ t }) => {
	const dispatch = useDispatch()
	const metros = useSelector((state) => state.search.metros)

	const handleClick = (metro) => {
		dispatch(setCityOrAddress(''))
		dispatch(setActiveMetro(metro))
	}

	return (
		<div className='py-12 bg-white'>
			<h2 className='text-center capitalize text-brand-dark-blue text-3xl lg:text-4xl font-bold'>
				{t('discover-our-locations', { ns: 'index' })}
			</h2>
			<hr className='h-[3px] w-[50px] mt-6 mb-10 mx-auto bg-brand-orange border-0' />
			<div className='px-6 lg:px-20'>
				<div className='hidden lg:flex justify-end gap-3 mb-3'>
					<div
						className='swiper-prev border rounded-full p-2 cursor-pointer'
						style={{ position: 'relative' }}
					>
						<CaretLeft size={24} weight='thin' />
					</div>
					<div
						className='swiper-next border rounded-full p-2 cursor-pointer '
						style={{ position: 'relative' }}
					>
						<CaretRight size={24} weight='thin' />
					</div>
				</div>
				<Swiper
					spaceBetween={16}
					slidesPerView={'auto'}
					modules={[Navigation]}
					navigation={{
						prevEl: '.swiper-prev',
						nextEl: '.swiper-next',
						disabledClass: 'opacity-30 cursor-auto pointer-events-none'
					}}
					breakpoints={{
						1024: {
							spaceBetween: 40
						}
					}}
					className='max-h-[360px]'
				>
					{metros.map((metro, i) => {
						return (
							<SwiperSlide key={i} className='max-w-xs'>
								<div className='p-6 h-[350px] rounded-2xl border max-w-xs'>
									<div className='relative h-[150px] w-[270px]'>
										<Image
											fill
											alt={metro.metro_name}
											src={
												metro.image ||
												'https://stuf-user-content.s3.us-east-2.amazonaws.com/cities/los-angeles.svg'
											}
											className='rounded-2xl'
											style={{ objectFit: 'cover' }}
											sizes='100vw'
										></Image>
									</div>
									<p className='text-xl mb-6 mt-3'>
										{t('storage-in', { ns: 'index' })} {metro.metro_name}
									</p>
									<Link legacyBehavior href={'/search-available-units'}>
										<Button
											variant='outlined'
											fullWidth
											sx={{
												borderRadius: 99,
												textTransform: 'capitalize',
												fontSize: '16px'
											}}
											onClick={() => handleClick(metro)}
										>
											{t('view-all-facilities', { ns: 'index' })}
										</Button>
									</Link>
								</div>
							</SwiperSlide>
						)
					})}
				</Swiper>
			</div>
			<div className='flex mt-6'>
				<Link legacyBehavior href={'/search-available-units'}>
					<Button
						sx={{
							m: 'auto',
							py: '12px',
							px: '40px',
							borderRadius: '99px',
							fontSize: '18px',
							textTransform: 'capitalize'
						}}
						onClick={() => handleClick(null)}
					>
						{t('explore-all-locations', { ns: 'index' })}
					</Button>
				</Link>
			</div>
		</div>
	)
}

export default withTranslation(TRANSLATIONS)(DiscoverNewLocations)
