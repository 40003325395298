import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Link from 'next/link'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { useTheme } from '@mui/material'
import { setActiveMetro, setCityOrAddress } from '../../slices/searchSlice'

const CitiesStates = ({
	cities = [
		{ city_name: 'Atlanta' },
		{ city_name: 'Boston' },
		{ city_name: 'Brooklyn' },
		{ city_name: 'Culver City' },
		{ city_name: 'Los Angeles' },
		{ city_name: 'New York' },
		{ city_name: 'Oakland' },
		{ city_name: 'Queens' },
		{ city_name: 'San Francisco' },
		{ city_name: 'Seattle' },
		{ city_name: 'Walnut Creek' },
		{ city_name: 'Washington, DC' }
	],
	states = [
		'California',
		'District of Columbia',
		'Georgia',
		'Massachusetts',
		'New York',
		'Washington'
	]
}) => {
	const theme = useTheme()
	const dispatch = useDispatch()
	const metros = useSelector((state) => state.search.metros)
	const [tabIndex, setTabIndex] = useState(0)

	const handleTabChange = (event, newValue) => {
		setTabIndex(newValue)
	}

	return (
		<div className='bg-brand-page-bg-5 p-12 sm:pb-20 xl:pb-12'>
			<Tabs
				value={tabIndex}
				onChange={handleTabChange}
				TabIndicatorProps={{
					sx: {
						backgroundColor: theme.palette.primary['blue'],
						height: '3px'
					}
				}}
			>
				<Tab
					label='Cities'
					sx={{
						fontSize: '1.5rem',
						color: theme.palette.primary['dark-blue'],
						fontWeight: 'regular',
						px: 0,
						mr: '2rem',
						minWidth: 'fit-content',
						textTransform: 'capitalize',
						'&.Mui-selected': {
							color: theme.palette.primary['blue'],
							fontWeight: 'bold'
						}
					}}
				/>
				<Tab
					label='States'
					sx={{
						fontSize: '1.5rem',
						color: theme.palette.primary['dark-blue'],
						fontWeight: 'regular',
						px: 0,
						minWidth: 'fit-content',
						textTransform: 'capitalize',
						'&.Mui-selected': {
							color: theme.palette.primary['blue'],
							fontWeight: 'bold'
						}
					}}
				/>
			</Tabs>
			{/* Top cities */}
			<div
				className={`${
					tabIndex === 0 ? 'grid sm:flex' : 'hidden'
				} grid-rows-6 grid-flow-col gap-3 sm:gap-5 w-full flex-wrap min-h-[80px] mt-6`}
			>
				{cities &&
					cities.map((city, i) => {
						const getMetro = (city) => {
							let metro = null
							switch (city) {
								case 'Atlanta':
									metro = metros.find((metro) => metro.metro_name === 'Atlanta')
									break
								case 'Boston':
									metro = metros.find((metro) => metro.metro_name === 'Boston')
									break
								case 'Culver City':
								case 'Los Angeles':
									metro = metros.find(
										(metro) => metro.metro_name === 'Los Angeles'
									)
									break
								case 'New York':
								case 'Queens':
								case 'Brooklyn':
									metro = metros.find(
										(metro) => metro.metro_name === 'New York'
									)
									break
								case 'San Francisco':
								case 'Oakland':
								case 'Walnut Creek':
									metro = metros.find(
										(metro) => metro.metro_name === 'San Francisco'
									)
									break
								case 'Seattle':
									metro = metros.find((metro) => metro.metro_name === 'Seattle')
									break
								case 'Washington, DC':
									metro = metros.find(
										(metro) => metro.metro_name === 'Washington DC'
									)
									break
								default:
									break
							}
							return metro
						}
						return (
							<Link legacyBehavior href={`/search-available-units`} key={i}>
								<a
									onClick={() => {
										dispatch(setActiveMetro(getMetro(city.city_name)))
									}}
									className='text-brand-dark-blue font-serif text-lg font-bold hover:underline focus:underline'
								>
									{city.city_name}
								</a>
							</Link>
						)
					})}
			</div>
			{/* States */}
			<div
				className={`${
					tabIndex === 1 ? 'grid sm:flex' : 'hidden'
				} grid-rows-6 grid-flow-col gap-3 sm:gap-5 w-full flex-wrap min-h-[80px] mt-6`}
			>
				{states &&
					states.map((state, i) => {
						const getMetro = (state) => {
							let metro = null
							switch (state) {
								case 'California':
									metro = metros.find(
										(metro) => metro.metro_name === 'Los Angeles'
									)
									break
								case 'District of Columbia':
									metro = metros.find(
										(metro) => metro.metro_name === 'Washington DC'
									)
									break
								case 'Georgia':
									metro = metros.find((metro) => metro.metro_name === 'Atlanta')
									break
								case 'Massachusetts':
									metro = metros.find((metro) => metro.metro_name === 'Boston')
									break
								case 'New York':
									metro = metros.find(
										(metro) => metro.metro_name === 'New York'
									)
									break
								case 'Washington':
									metro = metros.find((metro) => metro.metro_name === 'Seattle')
									break
								default:
									break
							}
							return metro
						}
						return (
							<Link legacyBehavior href={`/search-available-units`} key={i}>
								<a
									onClick={() => {
										dispatch(setActiveMetro(getMetro(state)))
									}}
									className='text-brand-dark-blue font-serif text-lg font-bold hover:underline focus:underline'
								>
									{state}
								</a>
							</Link>
						)
					})}
			</div>
		</div>
	)
}

export default CitiesStates
