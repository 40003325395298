// Libraries
import { useEffect, useState, useRef } from 'react'
import Image from 'next/image'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from '@mui/material'
import { useJsApiLoader } from '@react-google-maps/api'
// Content blocks
import CustomerFeedback from '../components/content-blocks/customer-feedback'
import FeaturedIn from '../components/content-blocks/featured-in'
import Hero from '../components/content-blocks/hero'
import WhyStoreWithUs from '../components/content-blocks/why-store-with-us'
import CitiesStates from '../components/content-blocks/cities-states'
import NotYourAverageStorage from '../components/content-blocks/not-your-average-storage'
import FindTheRightSize from '../components/content-blocks/find-the-right-size'
import DiscoverNewLocations from '../components/content-blocks/discover-new-locations'
// Other components and files
import Layout from '../components/layout'
import SearchBar from '../components/search-bar'
import { Seo } from '../components/seo'
import {
	checkGeolocationPermissionStatus,
	handleGeolocationPermissionsChange,
	getFacilitiesByOrgV2,
	getReviews,
	resetGetReviewsStatus
} from '../slices/rootSlice'
import { getMetros, resetGetMetrosStatus } from '../slices/searchSlice'
import { store } from '../store'
import { HEADER, TRANSLATIONS } from '../util/constants'
import mosaic from '../public/Mosaic-Multicolored.png'
import heroMosaic from '../public/hero-mosaic-images.png'

const libraries = ['geocoding', 'marker']

function useSearchBarObserver(ref) {
	const [isVisible, setVisible] = useState(true)

	useEffect(() => {
		if (typeof window === 'undefined' || !('IntersectionObserver' in window)) {
			return
		}
		if (!ref.current) {
			return
		}
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					setVisible(false)
				} else {
					setVisible(true)
				}
			},
			{
				rootMargin: '-80px 0px 0px 0px',
				threshold: 1.0
			}
		)

		if (ref.current) {
			observer.observe(ref.current)
		}

		return () => {
			if (ref.current) {
				observer.disconnect()
			}
		}
	}, [ref.current])

	return isVisible
}

function Home({ data }) {
	const { t } = useTranslation(TRANSLATIONS)
	const dispatch = useDispatch()
	const lg = useMediaQuery('(min-width: 1024px')
	const router = useRouter()
	const searchBarRef = useRef(null)
	const isSearchBarVisible = useSearchBarObserver(searchBarRef)
	const { isLoaded: isMapLoaded } = useJsApiLoader({
		googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
		libraries,
		version: 'weekly'
	})

	const isLoggedIn = useSelector((state) => state.root.isLoggedIn)
	const reviews = useSelector((state) => state.root.reviews)
	const getReviewsStatus = useSelector((state) => state.root.getReviewsStatus)
	const getMetrosStatus = useSelector((state) => state.search.getMetrosStatus)

	useEffect(() => {
		router.beforePopState(({ as }) => {
			const page = as.split('/')
			if (page[page.length - 1] === 'reserved') {
				router.replace('/')
				return false
			}
			return true
		})

		return () => {
			router.beforePopState(() => true)
		}
	}, [router])

	useEffect(() => {
		dispatch(checkGeolocationPermissionStatus())
		dispatch(getFacilitiesByOrgV2({ orgId: 1, fields: ['discount'] }))
		const unsubscribe2 = store.subscribe(handleGeolocationPermissionsChange)
		dispatch(getReviews())
		dispatch(getMetros())
	}, [dispatch])

	useEffect(() => {
		if (getReviewsStatus === 'FULFILLED' || getReviewsStatus === 'REJECTED') {
			dispatch(resetGetReviewsStatus())
		}
	}, [getReviewsStatus])

	useEffect(() => {
		if (getMetrosStatus === 'FULFILLED' || getMetrosStatus === 'REJECTED') {
			dispatch(resetGetMetrosStatus())
		}
	}, [getMetrosStatus])

	return (
		<Layout
			site={data.site}
			showFooter={true}
			headerType={HEADER.withNav}
			isLoggedIn={isLoggedIn}
			showBottomNav={false}
			headerVariant={'alternate-white'} //variants: blue / white / alternate-white. Default: white
			searchBarVisible={isSearchBarVisible}
			isMapLoaded={isMapLoaded}
		>
			<Seo route={data.route} site={data.site} />
			<section className='flex flex-col h-full lg:h-1/3 bg-brand-blue justify-center relative'>
				<Hero
					heading={t('main-slogan', { ns: 'index' })}
					subheading={t('main-promo-text', { ns: 'index' })}
				/>
				<SearchBar innerRef={searchBarRef} isMapLoaded={isMapLoaded} />
				{/* images */}
				<div className='mt-8 bg-white'>
					<Image
						src={lg ? heroMosaic : mosaic}
						alt=''
						sizes='100vw'
						layout='responsive'
					></Image>
				</div>
			</section>
			<section className='lg:hidden'>
				<FeaturedIn />
			</section>
			<section>
				<DiscoverNewLocations />
			</section>
			<section className='hidden lg:block mb-20'>
				<FeaturedIn />
			</section>
			<section>
				<CustomerFeedback reviews={reviews} />
			</section>
			<section className='relative'>
				<WhyStoreWithUs />
			</section>
			<section>
				<NotYourAverageStorage />
			</section>
			<section>
				<FindTheRightSize />
			</section>
			<section>
				<CitiesStates />
			</section>
		</Layout>
	)
}
export async function getServerSideProps({ locale }) {
	// Pass data to the page via props
	return {
		props: {
			...(await serverSideTranslations(locale, TRANSLATIONS)),
			data: {
				page: {},
				route: {
					openGraph: {
						title: 'Home',
						description: 'Stuf Storage Homepage'
					}
				},
				site: {
					openGraph: {
						description: ''
					}
				},
				cities: {}
			},
			revalidate: 5
		}
	}
}

export default Home
