import { useEffect, useState } from 'react'
import { withTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import Dialog from '@mui/material/Dialog'
import Popover from '@mui/material/Popover'
import { useMediaQuery } from '@mui/material'
import {
	resetSearchResultsFacilities,
	setActiveMetro,
	getSearchResults,
	resetSearchResults,
	resetGetSearchResultsStatus,
	setRecentSearch,
	setSearchValue,
	setSearchLocation
} from '../slices/searchSlice'
import SearchBarInput from '../atoms/search-bar'
import SearchResults from '../molecules/search-results'
import googleMapsUtil from '../util/google-maps'
import { TRANSLATIONS } from '../util/constants'
import { zip } from 'lodash'

const SearchBar = ({ innerRef, t, isMapLoaded }) => {
	const dispatch = useDispatch()
	const lg = useMediaQuery('(min-width: 1024px')
	const router = useRouter()

	const recentHistory = useSelector((state) => state.search.recentSearchHistory)
	const getSearchResultsStatus = useSelector(
		(state) => state.search.getSearchResultsStatus
	)
	const latitude = useSelector((state) => state.root.user.latitude)
	const longitude = useSelector((state) => state.root.user.longitude)
	const geolocationPermissionStatus = useSelector(
		(state) => state.root.user.geolocationPermissionStatus
	)
	const searchValue = useSelector((state) => state.search.searchValue)

	const [searchAnchorEl, setSearchAnchorEl] = useState(null)
	const [open, setOpen] = useState(false)
	const [showResults, setShowResults] = useState(false)

	const openSearchPopover = Boolean(searchAnchorEl)
	const searchPopoverId = openSearchPopover ? 'simple-popover' : null

	const handleClose = () => setOpen(false)

	useEffect(() => {
		if (showResults) {
			if (getSearchResultsStatus === 'FULFILLED') {
				if (lg) {
					const anchor = document.querySelector('#search-bar')
					setSearchAnchorEl(anchor)
				} else {
					setOpen(true)
				}
				dispatch(resetGetSearchResultsStatus())
				setShowResults(false)
			} else if (getSearchResultsStatus === 'REJECTED') {
				dispatch(resetGetSearchResultsStatus())
				setShowResults(false)
			}
		}
	}, [getSearchResultsStatus, showResults])

	const handlePopoverClose = () => {
		setSearchAnchorEl(null)
	}

	const onSearchBarBlured = () => {
		if (searchValue === '') {
			dispatch(resetSearchResults())
			setOpen(false)
		}
	}

	const onSearchBarSubmit = (e) => {
		if (e.keyCode === 13 || e.type === 'click') {
			if (searchValue === '') {
				dispatch(resetSearchResults())
				e.target.blur()
				return
			}
			setShowResults(true)
			e.target.blur()
			dispatch(getSearchResults(searchValue))
		}
	}

	const handleMyLocationClick = async () => {
		// get zip from location
		if (geolocationPermissionStatus === 0) {
			// Permission denied
			return
		}

		try {
			const results = await googleMapsUtil.getZipFromGoogle(
				isMapLoaded,
				latitude,
				longitude
			)
			let searchData = []
			results?.forEach((result) => {
				//Results is an array of addresses with diferent components, we are only looking for postal code
				// https://developers.google.com/maps/documentation/javascript/reference/geocoder#GeocoderResult
				const comp = result.address_components.find((item) => {
					return item.types.includes('postal_code')
				})

				// Prevent duplicates and null values
				if (
					comp &&
					searchData.filter((e) => e.zip_code === comp.long_name).length === 0
				) {
					searchData.push({
						zip_code: comp.long_name,
						state_abbr: null,
						latitude: latitude,
						longitude: longitude
					})
				}
			})

			dispatch(setSearchValue(searchData[0].zip_code || ''))
			dispatch(setSearchLocation(searchData[0]))
			handlePopoverClose()
			setOpen(false)
			router.push('/search-available-units', undefined, { shallow: true })
		} catch (error) {
			console.error(error)
		}
	}

	const handleSuggestionClick = (suggestion) => {
		dispatch(setSearchLocation(suggestion))
		if (suggestion.hasOwnProperty('zip_code')) {
			if (!recentHistory.some((e) => e.zip_code === suggestion.zip_code)) {
				dispatch(setRecentSearch(suggestion))
			}
		} else {
			if (!recentHistory.some((e) => e.city_name === suggestion.city_name)) {
				dispatch(setRecentSearch(suggestion))
			}
		}
		handlePopoverClose()
		setOpen(false)
		dispatch(setSearchValue(''))
		router.push('/search-available-units', undefined, { shallow: true })
	}

	return (
		<>
			<div className='px-5 lg:px-20 w-full relative' ref={innerRef}>
				<SearchBarInput
					value={searchValue}
					setValue={(value) => dispatch(setSearchValue(value))}
					onBlur={onSearchBarBlured}
					onSubmit={onSearchBarSubmit}
					id='search-bar'
					variant={lg && 'home'}
					placeholder={t('search-by-city-or-zip-code', { ns: 'index' })}
				/>
				<Popover
					id={searchPopoverId}
					open={openSearchPopover}
					anchorEl={searchAnchorEl}
					onClose={handlePopoverClose}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
					slotProps={{ paper: { sx: { maxWidth: '427px' } } }}
				>
					<SearchResults
						handleMyLocationClick={handleMyLocationClick}
						handleSuggestionClick={handleSuggestionClick}
					/>
				</Popover>
				<button
					onClick={onSearchBarSubmit}
					className='hidden transition-all duration-500 bg-brand-orange hover:bg-brand-dark-orange 
						text-white font-serif hover:text-brand-light-grey rounded-r-full justify-center h-[70px] absolute 
						right-0 mr-20 lg:inline-flex items-center hover:cursor-pointer text-lg min-w-[265px]'
				>
					{t('find-storage-near-me', { ns: 'index' })}
				</button>
			</div>
			<Dialog
				fullScreen
				open={open}
				onClose={handleClose}
				sx={{ zIndex: 39, mt: '82px' }}
			>
				<div className='p-4'>
					<SearchBarInput
						value={searchValue}
						setValue={(value) => dispatch(setSearchValue(value))}
						onSubmit={onSearchBarSubmit}
						onBlur={onSearchBarBlured}
					/>
				</div>
				<div className='overflow-scroll'>
					<SearchResults
						handleMyLocationClick={handleMyLocationClick}
						variant={'home'}
						handleSuggestionClick={handleSuggestionClick}
					/>
				</div>
			</Dialog>
		</>
	)
}

export default withTranslation(TRANSLATIONS)(SearchBar)
