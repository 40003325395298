import Button from '@mui/material/Button'
import { withTranslation } from 'next-i18next'
import Image from 'next/image'
import { useDispatch } from 'react-redux'
import { setActiveMetro, setCityOrAddress } from '../../slices/searchSlice'
import { TRANSLATIONS } from '../../util/constants'
import calendar from '../../public/Calendar.png'
import dollar from '../../public/Dollar.png'
import locationPin from '../../public/LocationPin.png'
import lock from '../../public/Lock.png'
import Link from 'next/link'

function WhyStoreWithUs({ t }) {
	const dispatch = useDispatch()

	const handleClick = () => {
		dispatch(setCityOrAddress(''))
		dispatch(setActiveMetro(null))
	}

	return (
		<div className='py-11 px-6 lg:px-11 lg:py-16'>
			<h2 className='text-center capitalize text-brand-dark-blue text-3xl lg:text-4xl font-bold'>
				{t('why_store_with_us', { ns: 'index' })}
			</h2>
			<hr className='h-[3px] w-[50px] mt-6 mb-10 lg:mb-[72px] mx-auto bg-brand-orange border-0' />
			<div className='flex justify-center flex-wrap gap-6 sm:gap-11 lg:gap-28'>
				<div className='grid grid-cols-2 lg:grid-cols-4 justify-evenly flex-wrap gap-6 sm:gap-11 lg:gap-28'>
					<div className='flex flex-col items-center'>
						<div className='relative mx-auto w-[100px] md:w-[150px]'>
							<Image src={dollar} alt='dollar' />
						</div>
						<p className='font-serif max-w-[200px] text-center relative z-20 mt-4 lg:text-xl'>
							{t('no-hidden-fees', { ns: 'index' })}
						</p>
					</div>
					<div className='flex flex-col items-center'>
						<div className='relative mx-auto w-[100px] md:w-[150px]'>
							<Image src={locationPin} alt='location pin' />
						</div>
						<p className='font-serif max-w-[200px] text-center relative z-20 mt-4 lg:text-xl'>
							{t('storage-near-home', { ns: 'index' })}
						</p>
					</div>
					<div className='flex flex-col items-center'>
						<div className='relative mx-auto w-[100px] md:w-[150px]'>
							<Image src={calendar} alt='calendar' />
						</div>
						<p className='font-serif max-w-[200px] text-center relative z-20 mt-4 lg:text-xl'>
							{t('month-to-month-leases', { ns: 'index' })}
						</p>
					</div>
					<div className='flex flex-col items-center'>
						<div className='relative mx-auto w-[100px] md:w-[150px]'>
							<Image src={lock} alt='lock' />
						</div>
						<p className='font-serif max-w-[200px] text-center relative z-20 mt-4 lg:text-xl'>
							{t('complimentary-padlock', { ns: 'index' })}
						</p>
					</div>
				</div>
			</div>
			<div className='flex mt-10 lg:mt-[72px]'>
				<Link legacyBehavior href={'/search-available-units'}>
					<Button
						variant='outlined'
						sx={{
							m: 'auto',
							borderRadius: '99px',
							py: '12px',
							px: '40px',
							fontSize: '18px',
							textTransform: 'capitalize'
						}}
						onClick={handleClick}
					>
						{t('find-a-storage-unit', { ns: 'index' })}
					</Button>
				</Link>
			</div>
		</div>
	)
}

export default withTranslation(TRANSLATIONS)(WhyStoreWithUs)
