import Button from '@mui/material/Button'
import { useTheme } from '@mui/material'
import Link from 'next/link'
import { useDispatch } from 'react-redux'
import { Cube } from '@phosphor-icons/react'
import { withTranslation } from 'react-i18next'
import { setActiveMetro, setCityOrAddress } from '../../slices/searchSlice'
import { TRANSLATIONS } from '../../util/constants'

const FindTheRightSize = ({ t }) => {
	const theme = useTheme()
	const dispatch = useDispatch()

	const handleClick = () => {
		dispatch(setCityOrAddress(''))
		dispatch(setActiveMetro(null))
	}

	return (
		<div className='px-6 lg:px-20 bg-brand-page-bg-5 border-b py-12 lg:py-28'>
			<h2 className='text-center capitalize text-brand-dark-blue text-3xl lg:text-4xl font-bold'>
				{t('find-the-right-size', { ns: 'index' })}
			</h2>
			<hr className='h-[3px] w-[50px] mt-6 mb-10 lg:mb-[72px] mx-auto bg-brand-orange border-0' />
			<div className='flex lg:justify-center overflow-x-scroll lg:overflow-x-auto gap-4 lg:gap-10 mb-10'>
				<div className='bg-white rounded-2xl border border-gray-200 p-8 min-w-[300px] max-w-[400px]'>
					<h3 className='flex gap-6 text-2xl font-semibold'>
						{t('small', { ns: 'common' })}
					</h3>
					<div className='flex gap-2 font-serif my-3'>
						<Cube size={24} weight='fill' color={theme.palette.primary.blue} />3
						x 3 {t('and-smaller', { ns: 'index' })}
					</div>
					<p className='font-serif'>
						{t('sizing-description-small', { ns: 'index' })}
					</p>
				</div>
				<div className='bg-white rounded-2xl border border-gray-200 p-8 min-w-[300px] max-w-[400px]'>
					<h3 className='flex gap-6 text-2xl font-semibold'>
						{t('medium', { ns: 'common' })}
					</h3>
					<div className='flex gap-2 font-serif my-3'>
						<Cube size={24} weight='fill' color={theme.palette.primary.blue} />3
						x 3 {t('to', { ns: 'common' })} 6 x 7
					</div>
					<p className='font-serif'>
						{t('sizing-description-medium', { ns: 'index' })}
					</p>
				</div>
				<div className='bg-white rounded-2xl border border-gray-200 p-8 min-w-[300px] max-w-[400px]'>
					<h3 className='flex gap-6 text-2xl font-semibold'>
						{t('large', { ns: 'common' })}
					</h3>
					<div className='flex gap-2 font-serif my-3'>
						<Cube size={24} weight='fill' color={theme.palette.primary.blue} />6
						x 7 {t('and-larger', { ns: 'index' })}
					</div>
					<p className='font-serif'>
						{t('sizing-description-large', { ns: 'index' })}
					</p>
				</div>
			</div>
			<div className='flex justify-center'>
				<Link legacyBehavior href={'/search-available-units'}>
					<Button
						variant='outlined'
						sx={{
							borderRadius: '99px',
							textTransform: 'capitalize',
							px: 5,
							fontSize: '18px'
						}}
						onClick={handleClick}
					>
						{t('explore-all-unit-sizes', { ns: 'index' })}
					</Button>
				</Link>
			</div>
		</div>
	)
}

export default withTranslation(TRANSLATIONS)(FindTheRightSize)
