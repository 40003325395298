import Image from 'next/image'
import Carousel from 'react-material-ui-carousel'

export default function FeaturedIn() {
	const blocks = [
		{
			link: {
				href: 'https://www.fastcompany.com/90685130/sign-of-the-times-this-startup-will-store-your-junk-in-abandoned-offices-and-restaurants'
			},
			logo: {
				src: 'logo-fast-company.svg',
				alt: 'Fast Company Logo'
			}
		},
		{
			link: {
				href: 'https://www.inc.com/magazine/202210/inc-staff/female-founders-company-growth-2022.html/'
			},
			logo: {
				src: '/logo-inc.svg',
				alt: 'Inc Logo'
			}
		},
		{
			link: {
				href: 'https://www.nytimes.com/2021/09/21/business/self-storage-roars-back.html?searchResultPosition=1'
			},
			logo: {
				src: 'logo-ny-times.svg',
				alt: 'New York Times Logo'
			}
		},
		{
			link: {
				href: 'https://finance.yahoo.com/news/self-storage-startup-stuf-secures-130000439.html'
			},
			logo: {
				src: 'logo-yahoo.svg',
				alt: 'Yahoo Finance Logo'
			}
		},
		{
			link: {
				href: 'https://www.forbes.com/sites/forbeseq/people/stuf/?sh=1db16fb3b35e'
			},
			logo: {
				src: 'logo-forbes.svg',
				alt: 'Forbes Logo'
			}
		}
	]
	return (
		<div className='h-24 lg:h-36 py-7 lg:py-14 bg-brand-page-bg-4'>
			{/* Desktop */}
			<div className='hidden lg:flex align-center justify-around px-5 xl:px-20'>
				{blocks.map((block, i) => (
					<a
						key={i}
						target='_blank'
						rel='noopener noreferrer'
						href={block.link.href}
						className='flex justify-center items-center hover:cursor-pointer'
					>
						<Image
							src={block.logo.src}
							width={240}
							height={30}
							alt={block.logo.alt}
							className='mx-auto h-[25px] lg:h-[30px]'
						/>
					</a>
				))}
			</div>
			{/* Mobile */}
			<div className='flex lg:hidden justify-center items-center'>
				<div className='w-2/3'>
					<Carousel indicators={false} interval={2000}>
						{blocks.map((block, i) => (
							<a
								target='_blank'
								rel='noopener'
								href={block.link.href}
								key={i}
								className='flex justify-center items-center hover:cursor-pointer'
							>
								<Image
									src={block.logo.src}
									alt={block.logo.alt}
									width={72}
									height={12}
									className='mx-auto h-12 w-72'
								/>
							</a>
						))}
					</Carousel>
				</div>
			</div>
		</div>
	)
}
