function Hero({ heading, subheading }) {
	return (
		<div className='flex flex-col justify-center h-full my-10 text-white text-center '>
			<h1 className='font-sans font-bold text-5xl sm:text-4xl md:text-5xl lg:text-6xl px-8 lg:px-48 py-6 inline z-10'>
				{heading}
			</h1>
			<div className='font-serif text-lg lg:text-2xl px-8 lg:px-48'>
				{subheading}
			</div>
		</div>
	)
}

export default Hero
