import Image from 'next/image'
import { Star } from '@phosphor-icons/react'
import { Swiper, SwiperSlide } from 'swiper/react'
// import required modules
import { Autoplay, Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'

import { useMediaQuery, useTheme } from '@mui/material'
import Rating from '@mui/material/Rating'
import { withTranslation } from 'react-i18next'
import { TRANSLATIONS } from '../../util/constants'
import stringUtil from '../../util/string'
import mosaic from '../../public/stuf-mosaic.png'

const CustomerFeedback = ({ reviews = [], t }) => {
	const theme = useTheme()
	const lg = useMediaQuery('(min-width: 1024px')

	return (
		<>
			<div className='flex flex-col items-center md:flex-row'>
				<div className='w-full pt-12 px-6 lg:py-20 lg:pr-0 lg:pl-20 xl:pl-40 relative overflow-hidden bg-brand-dark-blue lg:rounded-r-full lg:flex flex-col justify-center items-center  '>
					<div className='lg:max-w-lg'>
						<h2 className='text-white font-bold text-3xl lg:text-4xl text-center lg:text-left '>
							{t('what-stuf-members-are-saying', { ns: 'index' })}
						</h2>
						<hr className='h-[3px] w-[50px] mt-6 mb-10 bg-brand-orange border-0 mx-auto lg:ml-0' />
						{reviews?.length > 0 && (
							<Swiper
								autoplay={{ delay: 5000, disableOnInteraction: false }}
								pagination={{
									clickable: true,
									el: '.pagination',
									bulletClass:
										'bg-brand-light-blue w-4 h-4 mr-3 inline-block rounded-full ',
									renderBullet: function (index, className) {
										return '<span class="' + className + '"></span>'
									}
								}}
								loop
								modules={[Autoplay, Pagination]}
								spaceBetween={24}
							>
								{reviews.map((review, index) => {
									const splitName = review?.author?.split(' ')
									let name = splitName[0]
									if (splitName?.length > 1) name += ` ${splitName[1][0]}.`

									return (
										<SwiperSlide key={index}>
											<div className='font-serif mb-6 text-white pb-10'>
												<div className='flex flex-row items-center justify-between gap-4 max-w-[500px]'>
													<h3 className='font-bold uppercase text-2xl'>
														{name}
													</h3>
													<Rating
														readOnly
														value={parseInt(review.ratingValue)}
														sx={{ color: theme.palette.primary.orange }}
														icon={<Star size={24} weight='fill' />}
													/>
												</div>
												<p
													className='my-8 leading-6 max-w-[500px] max-h-[7.5em] lg:max-h-[5em] overflow-ellipsis overflow-hidden text-xl'
													style={{
														display: '-webkit-box',
														WebkitLineClamp: lg ? 4 : 6,
														WebkitBoxOrient: 'vertical'
													}}
												>
													{review.reviewBody}
												</p>
												<p className='text-sm '>
													{stringUtil.capitalizeFirstLetters(
														t('posted-on', { ns: 'common' })
													)}{' '}
													{stringUtil.capitalizeFirstLetters(review.source)}
												</p>
											</div>
										</SwiperSlide>
									)
								})}
								<div className='pagination absolute text-center lg:text-left z-10 w-full bottom-2'></div>
							</Swiper>
						)}
					</div>
				</div>
				<div className='w-full md:w-1/2 px-6 py-10 lg:p-20 bg-brand-dark-blue lg:bg-white'>
					<Image
						src={mosaic}
						alt='Storage facilities'
						layout='responsive'
						sizes='100vw'
						height={200}
						width={400}
					/>
				</div>
			</div>
		</>
	)
}

export default withTranslation(TRANSLATIONS)(CustomerFeedback)
